import React from "react";
import { Button, Select, Typography, message } from "antd";
import api from "../../api";
import useNotification from "../../hooks/useNotification";
import moment from "moment";
import { useState } from "react";
import { translate } from "../../translation/Translator";
import TextArea from "antd/lib/input/TextArea";

const ItemText = ({ changeFunc, label, value }) => {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 10,
      }}
    >
      <Typography.Text strong style={{ flex: 1, paddingRight: 10 }}>
        {label}{" "}
      </Typography.Text>
      <Typography.Text style={{ flex: 2 }}>{value}</Typography.Text>
    </span>
  );
};
export default function Request({ profile, data, dispatchReload, noteList }) {
  const [ROLE] = React.useState(localStorage.getItem("role"));
  const { pushNotifications } = useNotification();
  const [currentReason, setCurrentReason] = useState("");

  console.log(data);
  const request = data ? data[data.length - 1] : null;
  async function sumbitRequest(isAccept, error) {
    if (isAccept == 'onhold') {
      await api.put(`/requests/${request._id}`, {
        status: "onhold",
        amount: request.amount,
        error: error
      });
      pushNotifications({
        to: request.userId,
        message: currentReason,
        description: translate('NotificationMessage4') + currentReason,
      });
      message.success("Cập nhật thành công");
    }
    else if (isAccept == 'accepted') {
      await api.put(`/requests/${request._id}`, {
        status: "accepted",
        amount: request.amount,
        error: error
      });
      pushNotifications({
        to: request.userId,
        message: currentReason,
        description: translate('NotificationMessage4') + currentReason,
      });
      message.success("Cập nhật thành công");
    }
    else if (isAccept == 'pending') {
      await api.put(`/requests/${request._id}`, {
        status: "pending",
        amount: request.amount,
        error: error
      });
      pushNotifications({
        to: request.userId,
        message: currentReason,
        description: translate('NotificationMessage4') + currentReason,
      });
      message.success("Cập nhật thành công");
    }
    else {
      await api.put(`/requests/${request._id}`, {
        status: "rejected",
        amount: 0,
        error: error
      });
      pushNotifications({
        to: request.userId,
        message: currentReason,
        description: translate('NotificationMessage5') + currentReason,
      });
      message.success("Cập nhật thành công");
    }

    dispatchReload();
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        padding: 20,
        borderRadius: 10,
        minWidth: 420,
        margin: "0px 10px",
      }}
    >
      {request ? (
        <>
          <Typography.Title
            level={5}
            style={{ textAlign: "center", marginBottom: "0" }}
          >
            Lệnh rút tiền
          </Typography.Title>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <ItemText
              label="Ngân hàng"
              value={
                <>
                  {/* <img
                    style={{ marginRight: "20px" }}
                    height={"30px"}
                    src={profile?.kyc?.bank.logo}
                    alt="loading"
                  /> */}
                  {`${profile?.kyc?.bank.bankName}`}
                </>
              }
            />
            <ItemText label="Số tài khoản" value={profile?.kyc?.bank.number} />
            <ItemText label="tên tài khoản" value={profile?.kyc?.bank.name} />
            <ItemText label="Id chủ thẻ" value={profile?.kyc?.bank.idbank} />
            <ItemText label="Lệnh rút" value="" />
            <div
              style={{
                overflowY: "scroll",
                height: "150px",
                marginTop: "10px",
              }}
            >
              {data &&
                data.map((item) => {
                  return (
                    <div>
                      <span style={{ marginRight: "20px", fontWeight: "500" }}>
                        {moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")}
                      </span>
                      <span
                        style={{}}
                      >{`Lệnh rút ${item.amount.toLocaleString()} vnđ`}</span>
                    </div>
                  );
                })}
            </div>
          </div>

          <div
            className="reason"
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextArea
              style={{ width: "100%" }}
              placeholder={"Nhập lý do từ chối"}
              value={currentReason}
              onChange={(e) => setCurrentReason(e.target.value)}
            />
            {/* <Select
              style={{ width: "100%" }}
              placeholder={currentReason}
              value={currentReason}
              onChange={(e) => setCurrentReason(e)}
            >
              {noteList.map((item) => (
                <Select.Option value={`${item.value}`}>
                  {item.value}
                </Select.Option>
              ))}
            </Select> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "12px",
              }}
            >
              <p
                style={{
                  margin: "12px 0",
                  padding: "8px",
                  border: "1px solid #000",
                  width: "fit-content",
                }}
                onClick={() => sumbitRequest("rejected", currentReason)}
              >
                Từ chối
              </p>
              <p
                style={{
                  margin: "12px 0",
                  padding: "8px",
                  border: "1px solid #000",
                  width: "fit-content",
                }}
                onClick={() => sumbitRequest("accepted", currentReason)}
              >
                Chấp nhận
              </p>
              <p
                style={{
                  margin: "12px 0",
                  padding: "8px",
                  border: "1px solid #000",
                  width: "fit-content",
                }}
                onClick={() => sumbitRequest("onhold", currentReason)}
              >
                Cảnh báo
              </p>
              <p
                style={{
                  margin: "12px 0",
                  padding: "8px",
                  border: "1px solid #000",
                  width: "fit-content",
                }}
                onClick={() => sumbitRequest("pending", currentReason)}
              >
                Đang xử lý
              </p>
            </div>
          </div>
        </>
      ) : (
        <Typography.Text strong style={{ textAlign: "center" }}>
          Hiện không có yêu cầu
        </Typography.Text>
      )}
    </div>
  );
}
