import moment from "moment"
import logo_contract from "../../assets/logo_contract.png"
import signature from "../../assets/signature.jpg"
import { Typography } from "antd"

/* eslint-disable import/no-anonymous-default-export */
export default (props) => {
    const { contract = {}, profile = {} } = props

    const LOAN_RATE = 0.5
    const benA = {
        title: "BÊN A ( BÊN CHO VAY ) : Công Ty Tài Chính Cổ Phần Tín Việt (VietCredit)",
        des: [
            {
                title: "Người đại diện",
                value: "Hồ Minh Tâm"
            }, {
                title: "Chức vụ",
                value: "Tổng giám đốc"
            }, {
                title: "Mã số thuế 1",
                value: "0318175594"
            }, {
                title: "Mã số thuế 2",
                value: "0102766770-001"
            }, {
                title: "Ngày hoạt động",
                value: "15/11/2010"
            }, {
                title: "Hotline",
                value: "0965404444"
            }, {
                title: "Quản lý bởi",
                value: "Cục Thuế Thành phố Hà Nội"
            }, {
                title: "Loại hình DN",
                value: "Công ty trách nhiệm hữu hạn ngoài NN"
            }, {
                title: "Tình trạng",
                value: "Đang hoạt động (đã được cấp GCN ĐKT)"
            }, {
                title: "Sau đây gọi tắt là",
                value: "Bên A"
            }
        ]
    }

    const sugession = {
        title: "Bên Vay đề nghị và Bên Cho Vay (VietCredit) đồng ý cấp cho Bên Vay một Khoản Vay như sau:",
        des: [
            "Loại hình vay. Vay tín dụng thanh toán trả góp mỗi tháng gốc lẫn lãi.",
            "Phương thức cho vay: Giải ngân trực tiếp thông qua ứng dụng thanh toán online.",
            "Hình thức cho vay: cho vay trả góp không thế chấp tài sản.",
            "Phương thức giải ngân: chuyển khoản vào tài khoản của Bên Vay Cung cấp trên ứng dụng dịch vụ thanh toán online.",
            "Thời hạn cho vay: tính từ ngày tiếp theo của Ngày Giải Ngân và kết thúc tại thời điểm Bên Vay trả hết nợ gốc và lãi tiền vay theo Lịch Trả Nợ đính kèm hoặc tại thời điểm Bên Vay hoàn tất toàn bộ nghĩa vụ thanh toán Khoản Vay, tùy thuộc điều kiện nào đến trước."
        ]
    }

    const articles1 = {
        title: "VietCredit sẽ giải ngân Khoản Vay cho Bên Vay chỉ khi tất cả các điều kiện sau được thỏa mãn:",
        des: [
            "Hợp đồng này quy định các điều khoản chung điều chỉnh tất cả các dịch vụ mà Bên B cung cấp cho Bên A.",
            "Khi cung cấp một dịch vụ cụ thể, Bên B và Bên A sẽ ký kết để nêu rõ nội dung dịch vụ và các điều khoản cần thiết khác.",
            "Các điều khoản nêu trên là một phần không tách rời của hợp đồng này. Nếu có sự khác biệt giữa hợp đồng này và các điều khoản của hợp đồng này sẽ được áp dụng.",
            "Hợp đồng này có thời hạn kể từ ngày ký và kết thúc tại thời điểm Ngân Hàng chấm dứt sử dụng. Tất cả dịch vụ của Bên B tại các điều khoản đã ký."
        ]
    }
    const articles2 = [
        "Nếu số tiền thực nhận được ít hơn số tiền vay đã thỏa thuận trong hợp đồng này, một trong hai bên sẽ thông báo cho bên kia qua nền tảng trong 3 ngày và bên A sẽ thu xếp để số dư được cộng vào thẻ ngân hàng của bên B trong vòng một ngày làm việc, và thời gian cho vay sẽ tự động bắt đầu tính.",
        "Nếu số tiền nhận lớn hơn số tiền vay đã thỏa thuận trong hợp đồng này, một trong hai bên sẽ thông báo cho bên kia qua nền tảng hoặc điện thoại trong vòng ba ngày và bên B sẽ thu xếp hoàn trả số tiền vượt quá trong vòng một ngày làm việc. Nếu không số tiền ban đầu của khoản vay trong hợp đồng này sẽ được tính theo số tiền thanh toán thực tế.",
        "Sau khi người dùng qua vòng xét duyệt khoản vay, bộ phận giải ngân sẽ thẩm định. Nếu khách hàng đủ điều kiện xét duyệt thì bộ phận giải ngân sẽ cung cấp mật khẩu rút tiền và hỗ trợ khách hàng giải ngân.",
        "Bên A không sử dụng bất kỳ dịch vụ nào vào mục đích vi phạm pháp luật Việt Nam.",
        "Các Bên có nghĩa vụ tuân thủ các quy định của pháp luật Việt Nam về bảo vệ thông tin thu thập được từ Hợp đồng này.",
        "Nếu bên B không có tài sản đảm bảo như ô tô nhà đất do bên B đứng tên chính chủ thì bên B cần cọc 5 - 10% khoản vay để xác minh tài chính và khả nặng chỉ trả của bên B. Khoản cọc sẽ được trừ vào những tháng cuối của hợp đồng vay."
    ]
    const articles3a = [
        "Bảo đảm bảo mật thông tin của Bên B, chỉ được chuyển giao thông tin cho bên thứ ba khi có sự đồng ý của Bên B trừ các trường hợp theo quy định Pháp luật.",
        "Giải quyết khiếu nại của Bên B trong vòng 05 (năm) ngày làm việc kể từ ngày nhận được khiếu nại.",
        "Bồi thường thiệt hại trực tiếp do lỗi của mình gây ra cho Bên B.",
        "Thông báo cho Bên B về sự thay đổi các thông tin đã được Bên B cung cấp bằng văn bản trong ngày sau khi thay đổi hoặc nhận được yêu cầu tử Bên B. Hợp tác với Bên B để hoàn tất các thủ tục liên quan đến việc thay đổi thông tin nêu trên.",
    ]
    const articles3b = [
        "Chịu hoàn toàn trách nhiệm trước Pháp luật khi không thực hiện đúng cam kết theo Hợp đồng đã ký theo Bộ luật dân sự 2015 (BLDS 2015) (Luật số 91/2015/QH13 ngày 24/11/2015)",
        "Bên B vay vốn tổ chức tín dụng phải bảo đảm sử dụng vốn vay đúng mục đích, hoàn trả nợ gốc và lãi.",
        "Bồi thưởng thiệt hại trực tiếp do lỗi của mình gây ra cho Bên B.",
        "Thanh toán đầy đủ, đúng thời hạn các khoản cước theo quy định của hợp đồng này, kể cả trong thời gian chờ giải quyết khiếu nại và chịu các chi phí (như phí chuyển tiền, phí ngân hàng và các chi phí khác có liên quan, nếu có) phát sinh khi thanh toán cước.",
        "Không được từ chối giao kết hợp đồng hoặc đơn phương chấm dứt hợp đồng với Bên A trừ các trường hợp được quy định tại Điều 26 quỹ người vay.",
        "Khi người vay trong quá trình xét duyệt khoản vay không thành công do nhiều yếu tố khác nhau như chứng minh thư sai, thẻ ngân hàng sai, danh bạ sai. Việc thông tin sai lệch này sẽ khiến hệ thống phát hiện nghi ngờ gian lận hoặc giả mạo hồ sơ vay và bên vay phải có trách nhiệm chủ động hợp tác với bên A để xử lý theo quy định."
    ]
    const articles4 = {
        des: "Tất cả các bên trong hợp đồng sẽ thực hiện nghiêm túc các nghĩa vụ theo hợp đồng của mình và không bên nào được chấm dứt thỏa thuận này trừ khi các bên đạt được thỏa thuận thông qua tham vấn hoặc theo thỏa thuận này. Nếu bất kỳ bên nào vi phạm hợp đồng, bên vi phạm sẽ chịu các chi phí và tổn thất mà các bên không phải chịu do hành vi vi phạm, bao gồm nhưng không giới hạn ở phí điều tra, phí kiện tụng, phí luật sư, vv do bên vi phạm chịu.Nếu bên vỡ nợ là bên B thì bên A có quyền chấm dứt hợp đồng này ngay lập tức và yêu cầu bên B hoàn trả dư nợ gốc, lãi và các chi phí khác.Tại thời điểm này, bên B cung cấp thanh toán cho bên A tất cả các khoản phí, quản lý tài khoản, phí xét duyệt nhanh, phí quản lý tài khoản(nếu có) trong một khoảng thời gian, phí quản lý quá hạn ( nếu có ) và các khoản phí khác do khách hàng mất khả năng chi trả.",
        note: "Bên B thanh toán theo trình tự sau",
        content: [
            "Tất cả các khoản phí khác phát sinh theo thỏa thuận này.",
            "Phí quản lý tài khoản quá hạn theo quy định trên hợp đồng.",
            "Lãi quá hạn theo quy định.",
            "Phí quản lý tài khoản vay quy định.",
            "Phí duyệt hồ sơ nhanh.",
            "Phí quản lý tài khoản thông thường",
            "Phí tất toán hợp đồng 5%",
            "Phí đền bù hợp đồng 40% / tổng khoản vay",
        ],
        commit: "Tôi xin xác nhận những thông tin trên đây cùng với các thông tin và tài liệu khác được tôi cung cấp cho Bangkok Bank trong quá trình đề nghị vay vốn là chính xác, trung thực, và tôi cũng đồng ý cho VietCredit được thu thập, sử dụng cũng như chia sẻ cho bên thứ ba các thông tin liên quan đến tôi và thông tin cá nhân được ghi nhận tại bất kỳ tài liệu nào được ký bởi tôi (có hiệu lực tại từng thời điểm), thông tin trên CMND/Thẻ CCCD sẽ là thông tin cuối cùng và các tài liệu liên quan sẽ vẫn có giá trị pháp lý.",
        agree: "Tôi đồng ý ký kết Hợp Đồng này thông qua phương tiện điện tử. Trong mọi trường hợp"
    }

    return (
        <>
            <h5>
                <center>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</center>
            </h5>
            <h5>
                <center>ĐỘC LẬP - TỰ DO - HANH PHÚC</center>
            </h5>
            <br />
            <Typography.Text strong>
                <center>HỢP ĐỒNG VAY TIỀN</center>
            </Typography.Text>
            <br />
            <p>
                Bên A (Bên cho vay):{' '}
                <Typography.Text strong>
                    {' '}
                    Oriental Bank
                </Typography.Text>
            </p>
            <p>
                Bên B (Bên vay) Ông / Bà :
                <Typography.Text strong>
                    {profile?.kyc?.name || 'Cập nhật khi hoàn thành'}
                </Typography.Text>
            </p>
            <p>
                {' '}
                Số CMT / CCCD :
                <Typography.Text strong>
                    {profile?.kyc?.id_number || 'Cập nhật khi hoàn thành'}
                </Typography.Text>
            </p>
            <p>
                Ngày ký :
                <Typography.Text strong>
                    {' '}
                    {moment(contract?.created_at).format('hh:mm A DD/MM/YYYY')}
                </Typography.Text>
            </p>
            <p>
                Số tiền khoản vay :{' '}
                <Typography.Text strong>
                    {contract?.amount?.toLocaleString()} {("VNĐ")}
                </Typography.Text>
            </p>
            <p>
                Mã hợp đồng :{' '}
                <Typography.Text strong>
                    {contract?.slug || 'Cập nhật khi hoàn thành'}
                </Typography.Text>
            </p>
            <p>
                Thời gian vay :{' '}
                <Typography.Text strong>{contract?.times} tháng</Typography.Text>
            </p>
            <p>
                Lãi suất vay : <Typography.Text strong>{LOAN_RATE}%</Typography.Text> mỗi tháng
            </p>
            <p>
                Hợp đồng nêu rõ các bên đã đặt được thỏa thuận vay sau khi thương
                lượng và trên cơ sở bình đẳng , tự nguyện và nhất trí . Tất cả các bên
                cần đọc kỹ tất cả các điều khoản trong thỏa thuận này, sau khi ký vào
                thỏa thuận này coi như các bên đã hiểu đầy đủ và đồng ý hoàn toàn với
                tất cả các điều khoản và nội dung trong thỏa thuân này.
            </p>
            <p>
                1.Phù hợp với các nguyên tắc bình đẳng , tự nguyện , trung thực và uy
                tín , hai bên thống nhất ký kết hợp đồng vay sau khi thương lượng và
                cùng cam kết thực hiện.
            </p>
            <p>
                2.Bên B cung cấp tài liệu đính kèm của hợp đồng vay và có hiệu lực
                pháp lý như hợp đồng vay này.
            </p>
            <p>
                3.Bên B sẽ tạo lệnh tính tiền gốc và lãi dựa trên số tiền vay từ ví
                ứng dụng do bên A cung cấp.
            </p>
            <p>4.Điều khoản đảm bảo.</p>
            <p>
                - Bên vay không được sử dụng tiền vay để thực hiện các hoạt động bất
                hợp pháp .Nếu không , bên A có quyền yêu cầu bên B hoàn trả ngay tiền
                gốc và lãi , bên B phải chịu các trách nhiêm pháp lý phát sinh từ đó.
            </p>
            <p>
                - Bên vay phải trả nợ gốc và lãi trong thời gian quy định hợp đồng.
                Đối với phần quá hạn , người cho vay có quyền thu hồi nơ trong thời
                hạn và thu ( lãi quá hạn ) % trên tổng số tiền vay trong ngày.
            </p>
            <p>
                - Gốc và lãi của mỗi lần trả nợ sẽ được hệ thống tự động chuyển từ tài
                khoản ngân hàng do bên B bảo lưu sang tài khoản ngân hàng của bên A .
                Bên B phải đảm bảo có đủ tiền trong tài khoản ngân hàng trước ngày trả
                nợ hàng tháng.
            </p>
            <p>5.Chịu trách nhiệm do vi pham hợp đồng</p>
            <p>
                - Nếu bên B không trả được khoản vay theo quy định trong hợp đồng. Bên
                B phải chịu các khoản bồi thường thiệt hại đã thanh lý và phí luật sư,
                phí kiện tụng, chi phí đi lại và các chi phí khác phát sinh do kiện
                tụng.
            </p>
            <p>
                - Khi bên A cho rẳng bên B đã hoặc có thể xảy ra tình huống ảnh hưởng
                đến khoản vay thì bên A có quyền yêu cầu bên B phải trả lại kịp thời
                trược thời hạn.
            </p>
            <p>
                - Người vay và người bảo lãnh không được vi phạm điều lệ hợp đồng vì
                bất kỳ lý do gì
            </p>
            <p>
                6.Phương thức giải quyết tranh chấp hợp đồng. <br />
                Tranh chấp phát sinh trong quá trình thực hiện hợp đồng này sẽ được
                giải quyết thông qua thương lượng thân thiện giữa các bên hoặc có thể
                nhờ bên thứ ba làm trung gian hòa giải .Nếu thương lượng hoặc hòa giải
                không thành , có thể khởi kiện ra tòa án nhân dân nơi bên A có trụ sở.
            </p>
            <p>
                7.Khi người vay trong quá trình xét duyệt khoản vay không thành công
                do nhiều yếu tố khác nhau như chứng minh thư sai, thẻ ngân hàng sai ,
                danh bạ sai. Việc thông tin sai lệch này sẽ khiến hệ thống phát hiện
                nghi ngờ gian lận hoặc giả mạo khoản vay và bên vay phải chủ động hợp
                tác với bên A để xử lý.
            </p>
            <p>
                8.Nếu không hợp tác. Bên A có quyền khởi kiện ra Tòa án nhân dân và
                trình báo lên Trung tâm Báo cáo tín dụng của Ngân hàng nhà nước Việt
                Nam , hồ sơ nợ xấu sẽ được phản ánh trong báo cáo tín dụng , ảnh hưởng
                đến tín dụng sau này của người vay , vay vốn ngân hàng và hạn chế tiều
                dùng của người thân , con cái người vay ...
            </p>
            <br />


            <div style={{ display: 'flex', justifyContent: 'center', padding: '0 20px' }}>
                <div>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }} >Bên vay</p>
                    {
                        contract?.signature_capture
                        && <>
                            <img src={contract?.signature_capture} alt="" width={"100%"} />
                            <p>{profile?.kyc?.name}</p>
                        </>
                    }
                    {/* {
                        signature && <>
                            <img src={signature} alt="" width={"100%"} />
                            <p>{profile?.kyc?.name}</p>
                        </>
                    } */}
                </div>
            </div>
        </>
    );
}

export const currencyFormatVND = (value) => {
    let _displayValue = value;
    if (isNaN(_displayValue)) {
        _displayValue = 0
    }
    return new Intl.NumberFormat('vi-VN').format(Number(_displayValue)?.toFixed())
}